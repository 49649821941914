
.thumb-overlay img {
  max-width: 100%;
  max-height: unset;
}

img.thumb {
  max-width: min(100%, 500px);
  max-height: 300px;
  border: 1px dotted rgba(0, 0, 0, 0.5);
}

div.thumb {
  display: inline-block;
  overflow: hidden;
}

.thumb-overlay {
  position: fixed;
  z-index: 20;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  overflow-y: auto;
}

.thumb-overlay a {
  margin: auto;
}
